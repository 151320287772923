import { useState, useEffect } from "react";
import image1 from "../assets/media/icon_try_80.png";
import image2 from "../assets/media/icon_reach_80.png";
import image3 from "../assets/media/icon_bottle_80.png";
import image4 from "../assets/media/icon_footprint_80.png";
import image5 from "../assets/media/icon_logistic_80.png";
import image6 from "../assets/media/icon_marketing_80.png";
import image7 from "../assets/media/icon_gift_80.png";
import image8 from "../assets/media/icon_shelflife_80.png";
import image9 from "../assets/media/icon_tubes_80.png";
import image10 from "../assets/media/icon_inertgas_80.png";
import image11 from "../assets/media/icon_winespirits_80.png";
import image12 from "../assets/media/icon_save_80.png";

const TheBenefitsBlocks = () => {
  const TheBenefitsBlocksContent = [
    { content: "Let customers try before they buy", icon: image1 },
    { content: "Reach 7 – 14 clients with one bottle", icon: image2 },
    { content: "Create +/- 90 samples an hour", icon: image3 },
    { content: "Reduce your carbon footprint", icon: image4 },
    { content: "Decrease your logistic costs", icon: image5 },
    {
      content: "Improve marketing spend, loyalty and engagement",
      icon: image6,
    },
    {
      content: "Sell as gifts or (wine)club for customers and tourism",
      icon: image7,
    },
    {
      content:
        "Wine preservation for up to 4 to 6 months, and 12 months for robust protection of younger wines.",
      icon: image8,
    },
    { content: "Available in 100ml and 50ml glass tubes", icon: image9 },
    { content: "Only inert gas required, no electricity", icon: image10 },
    {
      content:
        "Suitable for wine and spirits producers, (e)retail en distributors",
      icon: image11,
    },
    {
      content: "Save wine and bottles, increase incremental value",
      icon: image12,
    },
  ];

  const [scales, setScales] = useState([
    1, 0.6, 0.8, 1, 0.6, 0.8, 1, 0.6, 0.8, 1, 0.6, 0.8,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setScales((prevScales) => {
        const newScales = prevScales.map((scale) =>
          scale === 1 ? 0.8 : scale === 0.6 ? 1 : 0.6
        );
        return newScales;
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex flex-wrap">
      {TheBenefitsBlocksContent.map((item, index) => (
        <div key={index} className="flex justify-start w-full lg:w-1/3">
          <div className="flex flex-grow justify-center min-h-fit p-2 m-4 bg-gradient-to-br from-tertiary to-primaryD text-white hover:from-primary hover:to-primaryD">
            <div className="w-1/4 h-24 rounded-full flex justify-center items-center bg-white">
              <img
                src={item.icon}
                alt="icoontje"
                className="w-24 h-24 p-4"
                style={{
                  transform: `scale(${scales[index]})`,
                  transition: "transform 3s linear",
                }}
              />
            </div>
            <p className="w-3/4 p-4 text-base">{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TheBenefitsBlocks;
