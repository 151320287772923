import image from "../assets/media/pricingMachine.jpg";
import HeadingH2 from "./HeadingH2";
import { Link } from "react-router-dom";
const PricingMachine = () => {
  const handleMenuItemClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-white w-full">
      <div className="flex flex-wrap relative bg-gradient-to-r from-white to-tertiary/30 w-full min-h-[700px] py-4">
        <div className="container text-tertiary text-4xl lg:text-5xl pt-8 lg:ps-20">
          <HeadingH2>
            The one-stop-shop
            <br />
            for wine and spirits sampling
          </HeadingH2>
        </div>
        <div className="w-full lg:w-1/2 relative flex items-center justify-center">
          <div className="relative z-10 h-full w-full flex items-center">
            <div className="relative w-full pb-[56.27198%]">
              <iframe
                title="youtube background"
                className="absolute top-0 left-0 w-full h-full -z-10"
                width="500"
                height="294"
                src="https://www.youtube.com/embed/w-Slurqt4OM?autoplay=1&amp;mute=1&amp;controls=0&amp;loop=1&amp;playlist=w-Slurqt4OM"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-4 lg:px-20 text-tertiary text-3xl lg:text-4xl flex flex-col justify-center items-center">
          <div>
            <HeadingH2>Pricing Machine</HeadingH2>
            <div className="p-2">
              <table className="text-center text-tertiary text-sm font-light">
                <tbody>
                  <tr className="border-b border-primary">
                    <th className="text-base">Buy</th>
                    <th className="text-base">
                      Lease - 36 months with option to break (12 months)
                    </th>
                  </tr>
                  <tr className="border-b border-primary">
                    <td className="py-2 px-4">€/$ 4.950</td>
                    <td className="py-2 px-4">€/$ 195</td>
                  </tr>
                  <tr className="border-b border-primary">
                    <td className="py-2 px-4">First 1.000 Tubes FREE</td>
                    <td className="py-2 px-4">First 275 Tubes FREE</td>
                  </tr>
                  <tr className="border-b border-primary">
                    <td className="py-2 px-4">50/100ML glass Tubes</td>
                    <td className="py-2 px-4">50/100ML glass Tubes</td>
                  </tr>
                  <tr className="border-b border-primary">
                    <td className="py-2 px-4">Free handwritten Labels</td>
                    <td className="py-2 px-4">Free handwritten Labels</td>
                  </tr>
                  <tr className="border-b border-primary">
                    <td className="py-2 px-4">3 year warranty + Support</td>
                    <td className="py-2 px-4">3 year warranty + Support</td>
                  </tr>
                  <tr className="">
                    <td className="py-2 px-4">€/$ 1.000 downpayment</td>
                    <td className="py-2 px-4">€/$ 600 downpayment</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap relative bg-gradient-to-r from-white to-tertiary/30">
        <div className="order-last lg:order-1 py-4 lg:py-2 xl:py-0 w-full lg:w-1/2 px-4 lg:px-20 xl:ps-40 text-tertiary text-3xl lg:text-4xl flex flex-col justify-center items-center">
          <div>
            <HeadingH2>TUBES & PACKAGING</HeadingH2>
            <h3 className="text-secondary text-lg">
              Purchase tubes according to your needs.
            </h3>
            <p className="lg:w-2/3  font-light text-base">
              Our Tubes come standaard with a pre-rolled screwcap and a free
              handwritten label.
              <br />
              Our standardized pricing is as follows:
            </p>
            <table className="lg:w-2/3 text-center text-sm font-light my-6">
              <tbody>
                <tr className="border-b border-primary">
                  <th className="text-base">Volume</th>
                  <th className="text-base">50ml</th>
                  <th className="text-base">100ml</th>
                </tr>
                <tr className="border-b border-primary">
                  <td className="py-2 px-4">1.000</td>
                  <td className="py-2 px-4">€ / $ 0,99</td>
                  <td className="py-2 px-4">€ / $ 1,19</td>
                </tr>
              </tbody>
            </table>
            <p className="text-secondary text-base lg:w-2/3 ">
              Looking for bigger volume? Just contact us and we can give you a
              quote!
            </p>
          </div>
        </div>
        <div className="lg:order-last w-full lg:w-1/2 z-10">
          <img
            width="475"
            height="320"
            className="w-auto h-auto"
            src={image}
            alt="Pricing Machine"
          />
        </div>
      </div>
      <div className="relative bg-gradient-to-r from-white to-tertiary/30 w-full py-8">
        <div className="w-full relative flex items-center justify-center">
          <Link
            to="/contact"
            onClick={handleMenuItemClick}
            className="mx-auto h-100 px-[1rem] py-[.5rem] bg-gradient-to-bl from-tertiary to-primaryD text-lg text-white fill-white font-semibold duration-300 hover:to-secondaryD hover:from-secondary"
          >
            Reach out for support
            <span className="inline-block  ms-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="13"
                viewBox="0 0 16 13"
              >
                <g>
                  <g>
                    <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                  </g>
                </g>
              </svg>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default PricingMachine;
