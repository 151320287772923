import { Link } from "react-router-dom";
import Logo from '../assets/media/logo.png';
import NavMenu from "../components/nav/NavMenu";

const Header = () => {

    return (
        <header className="bg-gradient-to-bl from-tertiary to-primaryD text-white text-lg h-[100px] lg:h-[150px] flex items-center justify-around">
            <Link to="/"><img width="" height="" className='max-h-[50px] lg:max-h-[75px] w-auto' src={Logo} alt="Logo" /></Link>
            <NavMenu />
        </header>
    )
};

export default Header;