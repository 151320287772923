import React, { useState } from 'react';

const FAQItem = ({ item }) => {
    const [showDiv, setShowDiv] = useState(false);

    const handleClick = () => {
        setShowDiv(!showDiv);
    };

    return (
        <>
            <div onClick={handleClick} className='py-8 px-4 cursor-pointer flex items-start justify-between'>
                <h3 className='text-xl font-semibold text-white hover:text-secondary'>{item.q}</h3>
                <span class="inline-flex items-center justify-center p-1 bg-gradient-to-bl from-secondaryD to-secondary rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4 text-white"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                </span>
            </div>
            {showDiv && (
                <div className="fixed lg:absolute left-0 top-0 right-0 bottom-0  flex items-center lg:justify-center z-50">
                    <div className="absolute left-0 top-0 right-0 bottom-0  bg-secondaryL bg-opacity-90"></div>
                    <div className="relative w-full lg:w-1/2 px-4 py-8 text-lg font-light bg-gradient-to-br from-tertiary to-primaryD text-white z-10">
                        <span className="absolute top-0 right-0 mx-4 text-[2.2rem] cursor-pointer text-white hover:text-gray-500" onClick={handleClick}>&times;</span>
                        <h3 className='text-xl font-semibold text-white mb-2'>{item.q}</h3>
                        <p>{item.a}</p>
                    </div>
                </div>
            )}
        </>

    )
}

export default FAQItem;
