import HeadingH2 from "../components/HeadingH2";
import { useEffect, useState } from "react";

const Contact = ({ page }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "5510076",
          formId: "dacd693c-66b5-4b98-87cc-40b2b4188b9a",
          target: "#hbspt-form-1687404736000-8715454201",
          onFormReady: () => setLoading(false), // Laadstatus bijwerken wanneer het formulier klaar is
        });
      }
    });
  }, []);
  return (
    <>
      <div className="relative">
        <div className="container min-h-[80vh] flex flex-col items-center z-10 relative">
          <div className="flex flex-wrap w-full py-4 lg:px-20 text-tertiary text-2xl">
            <div className="w-full lg:w-2/3 py-4 lg:pr-3">
              <div className="w-full py-4 text-tertiary text-4xl lg:text-6xl">
                <HeadingH2>Contact Us</HeadingH2>
                <h3 className="text-xl lg:text-3xl -mt-4 mb-5 text-secondary">
                  Got any questions? Please fill in our form and we will contact
                  you as soon as possible.
                </h3>
              </div>
              {loading && (
                <div className="absolute left-0 top-0 right-0 bottom-0  flex items-center justify-center z-50">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
                </div>
              )}
              <div id="hbspt-form-1687404736000-8715454201" />
            </div>
            <div className="w-full lg:w-1/3 py-4 lg:pr-3">
              <div className="bg-gradient-to-br from-tertiary to-primaryD text-white p-4">
                <h3 className="text-xl lg:text-3xl text-secondary">
                  ADDRESS EUROPE
                </h3>
                <p className="text-base mb-2">&nbsp;</p>
                <p className="text-base">
                  Franciscusweg 14,
                  <br />
                  1216 SK Hilversum,
                  <br />
                  The Netherlands
                </p>
                <a className="text-base hover:text-primary font-light" href="/">
                  Route via Google Maps
                </a>
                <p className="text-base mb-2">&nbsp;</p>
                <h3 className="text-xl lg:text-3xl text-secondary">
                  ADDRESS USA
                </h3>
                <p className="text-base mb-2">&nbsp;</p>
                <p className="text-base">
                  228 East 45th Street,
                  <br />
                  Suite 9E,
                  <br />
                  New York, NY 10017
                </p>
                <a className="text-base hover:text-primary font-light" href="/">
                  Route via Google Maps
                </a>
                <p className="text-base mb-2">&nbsp;</p>
                <h3 className="text-xl text-secondary mb-2">MAIL US</h3>
                <a
                  className="text-base hover:text-primary font-light"
                  href="mailto:info@revined.com"
                >
                  info@revined.com
                </a>
                <p className="text-base mb-2">&nbsp;</p>
                <h3 className="text-xl text-secondary mb-2">TUBES SALES</h3>
                <p className="text-base font-light text-primary">EUROPE</p>
                <a
                  className="text-base hover:text-primary font-light"
                  href="tel:+31353034994"
                >
                  +31 (0)35 303 4994
                </a>
                <p className="text-base font-light text-primary">USA</p>
                <a
                  className="text-base hover:text-primary font-light"
                  href="tel:+14165666025"
                >
                  +1 416 566 6025
                </a>
                <p className="text-base mb-2">&nbsp;</p>
                <h3 className="text-xl text-secondary mb-2">
                  Customer Service
                </h3>
                <p className="text-base hover:text-primary font-light">
                  <a
                    href="mailto:sales@revined.com"
                    className="hover:text-primary"
                  >
                    sales@revined.com
                  </a>
                </p>
                <p className="text-base hover:text-primary font-light">
                  <a href="tel:+31353034994" className="hover:text-primary">
                    +31 (0)35 303 4994
                  </a>
                </p>
                <p className="text-base mb-2">&nbsp;</p>
                <h3 className="text-xl text-secondary mb-2">TUBES FINANCE</h3>
                <a
                  className="text-base hover:text-primary font-light"
                  href="mailto:finance@revined.com"
                >
                  finance@revined.com
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="left-0 top-0 right-0 bottom-0 w-full h-full absolute bg-right bg-no-repeat"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "auto 100%",
          }}
        /> */}
        <div className="left-0 top-0 right-0 bottom-0 w-full h-full absolute bg-gradient-to-r from-white to-tertiary/30"></div>
      </div>
    </>
  );
};

export default Contact;
