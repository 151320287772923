const NotFound = () => {
    return (
        <>
        <div className="container">
            <p>Page Not Found</p>
        </div>
        </>
    )
}

export default NotFound;