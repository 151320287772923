import HeadingH2 from "../components/HeadingH2";
import caseImage1 from "../assets/media/useCaseRetail.jpg";
import caseImage2 from "../assets/media/useCaseWineClub.jpg";
import caseImage3 from "../assets/media/useCaseTradeSampling.jpg";
import caseImage4 from "../assets/media/useCaseWineEdu.jpg";
import caseImage5 from "../assets/media/useCaseVirtualTastings.jpg";
import caseImage6 from "../assets/media/useCaseCollectingData.jpg";
import TopBox from "../components/TopBox";

const UseCases = ({ page }) => {
  const Usescases = [
    {
      image: caseImage1,
      title: "Retail & E-commerce",
      content:
        "Elevate your e-retail experience. Unlock a range of benefits for your business:<ul class='list-disc'><li>Craft exclusive tasting sets, inviting customers to explore flavors and aromas before purchasing</li><li>Expand your audience base and attract newcomers</li><li>Streamline inventory management for a seamless shopping experience</li><li>Retain loyal customers by offering a chance to try before they buy</li><li>Enhance customer engagement</li></ul> ",
    },
    {
      image: caseImage2,
      title: "Wine clubs & events",
      content:
        "Maximize the potential of your wine clubs and events. Elevate the experience for your members and attendees in ways that make your offerings truly stand out:<ul class='list-disc'><li>Distinguish yourself by offering a unique way to present your wines, making your club or event a memorable experience</li><li>Personalize loyalty programs by strengthening the bond with your participants by personalizing their experience. TUBES Revined allows you to tailor wine selections based on their preferences</li><li>Launch limited editions and entry-level tastings that showcase the extraordinary range of your products. Inviting new clients to explore and join your community</li></ul>",
    },
    {
      image: caseImage3,
      title: "Trade sampling",
      content:
        "Amplify your trade sampling initiatives with TUBES Revined, revolutionizing your approach to presenting your wine offerings:<ul class='list-disc'><li>Create personalized sampling experiences. TUBES Revined allows you to curate tailor-made wine tasting experiences, making each engagement unique and memorable for your trade partners. Impress them with a diverse range of flavors, right at their fingertips</li><li>Flexibility in offering your collection. TUBES Revined allows you to display your wines in various ways, increasing the likelihood of finding the perfect match for every taste</li><li>Immediate and efficient shipping. Eliminate delays and provide a seamless experience, your samples are always ready to ship</li><li>Focus on sustainability. By opting for sample-sized shipments instead of full bottles, TUBES Revined helps minimize your carbon footprint</li><li>Cost-effective sampling. Experience cost efficiency without compromising on the quality of your presentation</li></ul>",
    },
    {
      image: caseImage4,
      title: "Wine education",
      content:
        "Discover how TUBES Revined is transforming wine education, offering valuable tasting opportunities for sommeliers, virtual trade events, students, and consumer tastings.<ul class='list-disc'><li>Elevate sommelier training with hands-on tasting experiences</li><li>Enrich virtual trade events by providing a tangible tasting element</li><li>Empower students with practical exposure to diverse wine profiles</li><li>Engage consumers in educational tastings that enhance their wine knowledge</li></ul>",
    },
    {
      image: caseImage5,
      title: "Virtual tastings",
      content:
        "With TUBES Revined, your virtual tastings transcend the limitations of distance, bringing your wines to life for a diverse and engaged audience. Whether it's boosting sales or creating a sense of togetherness, TUBES Revined redefines what virtual wine tastings can be:<ul class='list-disc'><li>Elevate opportunities by empowering to upsell higher segment wines by giving your customers a tantalizing taste. Virtual tastings become an immersive journey, offering a real-time encounter with your premium selections</li><li>Reach beyond physical boundaries by connecting with customers who can't physically visit your winery. TUBES Revined bridges the gap, allowing you to interact with a broader audience and expand your reach.</li></ul>",
    },
    {
      image: caseImage6,
      title: "Collecting data",
      content:
        "In today's dynamic business landscape, understanding your customers and their preferences is important. TUBES Revined presents a perfect solution for delving into the minds of your clients, allowing you to gain insights about your wine offerings:<ul class='list-disc'><li>Incorporating TUBES Revined into your data collection strategy amplifies your understanding of your audience, fostering a more effective and customer-centric approach to your wine business</li><li>Refine your strategy by collecting data through surveys, social media and reviews. Engage with your audience and decode the nuances of your customers' preferences. Understand what makes them tick, enabling you to tailor your offerings to match their desires</li></ul>",
    },
  ];
  return (
    <>
      <TopBox data={page.page_content} />
      {Usescases.map((item, index) => (
        <div
          key={index}
          className={`relative w-full py-8 ${
            index % 2 === 0
              ? "bg-gradient-to-br from-tertiary to-primaryD text-white"
              : "bg-gradient-to-r from-white to-tertiary/30 text-tertiary"
          } `}
        >
          <div className="container min-h-[50vh] flex flex-wrap justify-center text-4xl lg:text-6xl">
            <div
              className={`w-full flex flex-col justify-center lg:w-1/2 ${
                index % 2 === 0 ? "lg:order-1 pr-6" : "lg:order-2 pl-6"
              }`}
            >
              <HeadingH2>{item.title}</HeadingH2>
              <p
                className="text-base"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
            <div
              className={`w-full flex flex-col justify-center lg:w-1/2 ${
                index % 2 === 0 ? "lg:order-2 pl-6" : "lg:order-1 pr-6"
              }`}
            >
              <img src={item.image} alt={item.title} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default UseCases;
