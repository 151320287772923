import React from 'react';

const HeadingH2 = ({ children }) => {
  return (
    <h2 className="relative mb-5 w-full">
      <span className="absolute bg-secondary h-full w-2 -left-5"></span>
      {children}
    </h2>
  );
};

export default HeadingH2;
