import topImage from "../assets/media/revinedbg.jpg";
import HeadingH2 from "./HeadingH2";
import YouTubeInPage from "./YouTubeInPage";
import { Link } from "react-router-dom";

const TopBox = ({ data }) => {
  const handleMenuItemClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="relative">
      <div className="container mx-auto  min-h-[70vh] flex flex-wrap items-center z-10 relative">
        <div className="w-full lg:w-1/2 py-4 lg:p-20 text-tertiary text-4xl lg:text-6xl">
          <HeadingH2>{data.boxTitle}</HeadingH2>
          {data.boxSubTitle && (
            <h3 className="text-2xl lg:text-3xl -mt-4 mb-5 text-secondary">
              {data.boxSubTitle}
            </h3>
          )}
          {data.box && (
            <p
              className="text-base lg:text-lg font-light"
              dangerouslySetInnerHTML={{ __html: data.box }}
            />
          )}
          {data.boxBtn && (
            <Link
              to={data.boxBtnUrl}
              onClick={handleMenuItemClick}
              className="h-100 px-[1rem] py-[.5rem] bg-gradient-to-bl from-tertiary to-primaryD text-lg text-white fill-white font-semibold duration-300 hover:to-secondaryD hover:from-secondary"
            >
              {data.boxBtn}
              <span className="inline-block  ms-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                >
                  <g>
                    <g>
                      <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                    </g>
                  </g>
                </svg>
              </span>
            </Link>
          )}
        </div>
        <div className="w-full lg:w-1/2 py-4 lg:p-20">
          {data.youtubeId ? (
            <YouTubeInPage videoId={data.youtubeId} thumbnailUrl={topImage} />
          ) : (
            <img src={topImage} alt={data.boxTitle} />
          )}
        </div>
      </div>
      {/* <div className='left-0 top-0 right-0 bottom-0 w-full h-full absolute bg-right bg-no-repeat' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'auto 100%' }} /> */}
      <div className="left-0 top-0 right-0 bottom-0 w-full h-full absolute bg-gradient-to-r from-white to-tertiary/30"></div>
    </div>
  );
};

export default TopBox;
