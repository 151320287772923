import WaterLogo from '../../assets/media/LogoWaterOrg.png';

const Partner = () => {
    return (
        <section className='bg-tertiary relative w-full'>
            <div className='container text-center text-white flex flex-col items-center'>
                <a href="https://water.org/" target='_blank' rel='noreferrer'><img src={WaterLogo} alt='Water.org partner Logo' /></a>
                <p className='pb-3 font-light'>
                    TUBES is a proud partner of <a href="https://water.org/" target='_blank' rel='noreferrer' className='text-[#7395a5] font-semibold hover:text-[#b6a97a]'>Water.org</a>, an international nonprofit organization that is dedicated to solving the global water crisis.
                    <br />
                    They help families around the world get access to safe water and sanitation. <a href="https://wineintubes.com/water-org" target='_blank' rel='noreferrer' className='text-[#7395a5] font-semibold hover:text-[#b6a97a]'>Read more</a>
                </p>
            </div>
            
        </section>
    )
};

export default Partner;