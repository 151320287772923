import PricingMachine from "../components/PricingMachine";
import QuoteCustomer from "../components/QuoteCustomer";
import RevinedCalc from "../components/RevinedCalc";
import TheConcept from "../components/TheConcept";
import TopBox from "../components/TopBox";
import UseCase from "../components/UseCase";
import Why from "../components/Why";

const Home = ({page}) => {
    return (
        <>
            <TopBox data={page.page_content} />
            <Why data={page.page_content} />
            <TheConcept data={page.page_content} />
            <QuoteCustomer data={page.page_content} />
            <UseCase data={page.page_content} />
            <RevinedCalc data={page.page_content} />
            <PricingMachine data={page.page_content} />
        </>
    );
};

export default Home;