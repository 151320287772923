import React, { useEffect } from 'react';
import HubspotContactForm from '../components/HubspotContactForm';

const PreOrderNow = ({page}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: 5510076,
          formId: 'dacd693c-66b5-4b98-87cc-40b2b4188b9a',
          target: '#hbspt-form-1687404736000-8715454201',
          region: 'na1',
        });
      }
    });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
        <HubspotContactForm />
    </>
  );
};

export default PreOrderNow;
