import { useState, useEffect } from 'react';
import image1 from '../assets/media/icon_connect.png';
import image2 from '../assets/media/icon_bottle.png';
import image3 from '../assets/media/icon_seal.png';

const HowItWorksBlocks = () => {

    const HowItWorksBlocksContent = [
        { title: 'Connect', content: 'Easily connect the machine to your inert-gas tank and attach your bottle of wine.', icon: image1 },
        { title: 'Create', content: 'Fill up 50ml or 100ml tubes in a blink of an eye. Create over 90 samples an hour.', icon: image2 },
        { title: 'Taste', content: 'Send your tubes to your tasting partners, and let them enjoy them for at least 4-6 months. And even longer for younger wines with robust protection, which can be preserved for up to 12 months.', icon: image3 },
    ];

    const [scales, setScales] = useState([1, 0.9, 1.1]);

    useEffect(() => {
        const interval = setInterval(() => {
            setScales((prevScales) => {
                const newScales = prevScales.map((scale) => (scale === 1 ? 1.1 : (scale === 0.9 ? 1 : 0.9)));
                return newScales;
            });
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className='flex flex-wrap'>
            {HowItWorksBlocksContent.map((item, index) => (
                <div key={index} className="flex justify-start w-full lg:w-1/3">
                    <div className="flex flex-col flex-grow p-8 m-8 bg-gradient-to-bl from-secondaryD to-secondary text-white hover:from-secondary hover:to-secondary">
                        <div className='w-36 h-36 rounded-full flex justify-center items-center bg-white mx-auto mb-4'>
                            <img src={item.icon} alt='icoontje' className='w-36 h-36 p-8' style={{
                                transform: `scale(${scales[index]})`,
                                transition: 'transform 2s linear',
                                }} />
                        </div>
                        <h3 className='uppercase text-xl font-semibold mb-2'>{item.title}</h3>
                        <p className='text-base'>{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HowItWorksBlocks;
