const QuoteCustomer = ({ data }) => {
    console.log(data);
    return (
        <div className='relative bg-gradient-to-br from-tertiary to-primaryD w-full py-24'>
            <div className='container text-secondaryL text-center font-light text-4xl lg:text-5xl lg:ps-20'>
                <i>{data.quoteCustomer}</i>
            </div>
        </div>
    );
};

export default QuoteCustomer;