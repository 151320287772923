const Newsletter = () => {
    return (
        <>
        <h3 className='text-3xl pb-3 uppercase'>Newsletter</h3>
        <p className='pb-3 font-light'>Sign up for our newsletter to receive notifications about product launches and updates.</p>
        <form method='post' className='flex gap-1'>
            <input type='email' name='email' required='required' placeholder='Your e-mail address' className='my-3 py-2 px-3 w-full border-2 border-white bg-transparent' />
            <button type='submit' className='my-3 py-2 px-3 bg-gradient-to-bl from-white to-tertiaryL text-lg text-tertiary fill-tertiary font-semibold duration-300 hover:to-secondaryD hover:from-secondary hover:text-white hover:fill-white'><span className='inline-block'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13"><g><g><path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z"/></g></g></svg></span></button>
        </form>
        </>
    )
};

export default Newsletter;