import React from 'react';
import { Link } from 'react-router-dom';

const NavMenuLink = ({ to, onClick, className, children }) => (
  <Link to={to} onClick={onClick} className={className}>
    {children}
  </Link>
);

const NavMenuItems = ({ handleMenuItemClick, isOpen }) => {
  const menuItems = [
    { name: 'Home', to: '/', type: 'default' },
    { name: 'The Product', to: '/the-product', type: 'default' },
    { name: 'Use Case', to: '/use-case', type: 'default' },
    { name: 'About Us', to: '/about-us', type: 'default' },
    { name: 'Contact', to: '/contact', type: 'default' },
    { name: 'Request a Demo', to: '/pre-order-now', type: 'special' },
  ];

  return (
    <>
      {menuItems.map((item, index) => (
        <NavMenuLink
          key={index}
          to={item.to}
          onClick={handleMenuItemClick}
          className={`block py-4 border-0 lg:inline-block lg:mt-0 lg:h-100 px-[12.5px] text-lg duration-300 ${item.type === 'special' ? `h-100 px-[1rem] py-[.5rem] bg-gradient-to-bl from-white to-tertiaryL text-lg text-tertiary fill-tertiary font-semibold duration-300 hover:to-secondaryD hover:from-secondary hover:text-white hover:fill-white ${isOpen ? 'block w-fit ms-3 mt-5' : 'ms-5'}` : 'hover:text-secondary'}`}
        >
          {item.name}
          {item.type === 'special' ? <span className='inline-block  ms-4'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13"><g><g><path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z"/></g></g></svg></span> : ''}
        </NavMenuLink>
      ))}
    </>
  );
};

export default NavMenuItems;
