import React, { useState } from 'react';

const ProductPointsItem = ({ left, bottom, children }) => {
    const [showDiv, setShowDiv] = useState(false);

    const handleClick = () => {
        setShowDiv(!showDiv);
    };

    if (left > 49) {
        return (
            <div className={`absolute left-[${left}%] bottom-[${bottom}%] ${showDiv && 'border-b-2 border-r-2'} border-secondary`}>
                <div className={`max-w-[190px] text-center text-white translate-x-1/2 -translate-y-full  bg-gradient-to-bl from-[#b6a97a] to-[#756d4f] rounded-lg ${showDiv ? 'visible z-20' : 'invisible -z-10'}`}>
                    <div className='p-2'>{children}</div>
                </div>
                <span className='relative z-10 cursor-pointer flex items-center justify-center h-7 w-7 text-white  bg-gradient-to-bl from-[#b6a97a] to-[#756d4f] rounded-full -translate-x-full translate-y-1/2' onClick={handleClick}>
                    +
                </span>
            </div>
        );
    } else {
        return (
            <div className={`absolute left-[${left}%] bottom-[${bottom}%] ${showDiv && 'border-b-2 border-l-2'} border-secondary -translate-x-full flex`}>
                <div className={`max-w-[190px] min-h-[56px] text-center text-white -translate-x-1/2 -translate-y-full  bg-gradient-to-bl from-[#b6a97a] to-[#756d4f] rounded-lg ${showDiv ? 'visible z-20' : 'invisible -z-10'}`}>
                    <div className='p-2 text-sm'>{children}</div>
                </div>
                <span className='relative z-10 cursor-pointer flex items-center translate-y-10 justify-center h-7 w-7 text-white  bg-gradient-to-bl from-[#b6a97a] to-[#756d4f] rounded-full' onClick={handleClick}>
                    +
                </span>
            </div>
        );
    }
};

export default ProductPointsItem;
