import FAQItem from "./FAQItem";


const FAQItems = () => {

    const FAQItemsContent = [
        { q: 'Want to become a customer?', a: 'If you want to become a customer, please fill in the form on this website. We will get in contact with you as soon as possible. Please know, our first inventory is limited, confirmations are made in order of signed orders and down payments.' },
        { q: 'How long does it take before I receive my machine?', a: 'Currently we work on pre-order. Depending on the manufacturing period, the first machines will be shipped out in September 2023. The exact delivery dates will be communicated when an order is confirmed.' },
        { q: 'What countries do you sell, ship and offer services to?', a: 'Our first focus are wine and spirits producing countries within the European Union. We are able to deliver also in the United States of America, Argentina, Chile, Uruguay, South Africa, Australia and New Zealand, but additional tariffs can occur. Please get in touch with our Customer Service.' },
        { q: 'What are the technical specifications and how do the warranty and maintenance work?', a: 'The technical specifications and dimensions can be downloaded at www.revined.com. When a part supplied by TUBES Revined does not work as advertised, TUBES Revined will repair or replace the machine. When in need of a replacement part, please inform our Customer Service.' },
        { q: 'Which liquids and tubes are suitable for the machine?', a: 'The machine is suitable for regular, non-carbonated wines and spirits. Special attention is needed for sweet wines and old vintages (10+ years). Please get in touch with our Customer Service in case you need advice. Only 100ml and 50ml glass tubes delivered by TUBES Revined are suitable for the machine. Other vessels do not comply with our quality, indications and/or processes.' },
        { q: 'What is the indicated best before?', a: 'With TUBES Revined, you can now enjoy the flavors and aromas of your favorite wines for up to 4 to 6 months, and even longer for younger wines with robust protection, which can be preserved for up to 12 months. To receive personalized advice tailored to your specific wines, TUBES encourages customers to reach out to our team of wine experts. Our team is available to provide expert guidance on optimal preservation techniques, ensuring that your wine collection remains in pristine condition.' },
        { q: 'How does your business model work?', a: 'The machines can be bought or leased. When you buy the machine you will own the machine directly. At order, we have a pre-payment of 50% and 50% before delivery. The purchase price includes 1.000 tubes, to be chosen between 50ml or 100ml, and free labels. The lease model is according to a 36-months lease agreement. The costs per month are € 195, and the lease is invoiced per quarter. The invoice will be sent one week before the new quarter and have a payment term of 14 days. As a deposit, each machine will be delivered after a pre-payment of two quarters. You can cancel your lease agreement free of charge after 12 months. Tubes and packaging can be ordered according to your needs and are invoiced separately with a payment term of 14 days.' },
        { q: 'Can I cancel or transfer my lease agreement and what happens after 36 months?', a: 'After 12 months, you can cancel your monthly lease free of charge. Get in touch with our Customer Service to ship back the machine. The machine needs to be received in the original packaging, without irreparable damage. You can transfer the contract to someone else free of charge during the duration of the contract. To do so, please contact our Customer Service. After 36 months, our Customer Service will offer you a purchase option or replacement option for the machine. You\'re not obliged to accept the offer. In that case, we will take back the machine.' },
        { q: 'What are the conditions to buy or lease TUBES Revined?', a: 'Our focus is to work with professional producers and brands to contribute to improving their professional sampling program. We have the following conditions: - Signing customer must be at least 18 years old and officially work for the customer. - We use your entered data for a credit test. After a pre-payment and positive credit test we continue the order. - Our services are subject to our terms and conditions, which you can download in the footer of our website.' },
    ];
    return (
        <>
            {FAQItemsContent.map((item, index) => (
                <div key={index} className="w-full lg:w-1/2">
                    <FAQItem item={item} />
                </div>
            ))}
        </>
    );
};
export default FAQItems;