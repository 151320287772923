const ContactLocation = ({ location }) => {
  if (location === "US") {
    return (
      <>
        <h3 className="text-3xl pb-3 uppercase">Contact USA</h3>
        <p className="pb-3 font-light text-center">
          TUBES USA Inc.,
          <br />
          265-A Lombard Road,
          <br />
          American Canyon, CA, 94503
          <br />
          &nbsp;
        </p>
        <p>
          <a
            href="mailto:sales@revined.com"
            className="text-[#7395a5] font-semibold hover:text-[#b6a97a]"
          >
            sales@revined.com
          </a>
          <br />
          <a
            href="tel:+14165666025"
            className="text-[#7395a5] font-semibold hover:text-[#b6a97a]"
          >
            +1 416 566 6025
          </a>
        </p>
      </>
    );
  } else if (location === "EU") {
    return (
      <>
        <h3 className="text-3xl pb-3 uppercase">Contact Europe</h3>
        <p className="pb-3 font-light text-center">
          TUBES B.V.
          <br />
          Franciscusweg 14,
          <br />
          1216 SK Hilversum,
          <br />
          The Netherlands
        </p>
        <p>
          <a
            href="mailto:sales@revined.com"
            className="text-[#7395a5] font-semibold hover:text-[#b6a97a]"
          >
            sales@revined.com
          </a>
          <br />
          <a
            href="tel:+31353034994"
            className="text-[#7395a5] font-semibold hover:text-[#b6a97a]"
          >
            +31 (0)35 303 4994
          </a>
        </p>
      </>
    );
  }
};

export default ContactLocation;
