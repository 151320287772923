import HeadingH2 from "./HeadingH2";
import WhyBlocks from "./WhyBlocks";

const Why = ({data}) => {
    return (
        <section className='bg-gradient-to-br from-tertiary to-primaryD relative w-full py-8'>
            <div className='container text-white text-4xl lg:text-5xl lg:px-20'>
                <HeadingH2>{data.whyTitle1}<br/>{data.whyTitle2}</HeadingH2>
                <h3 className='text-2xl lg:text-3xl -mt-4 mb-5 text-secondary'>{data.whySubTitle}</h3>
                <WhyBlocks data ={data} />
            </div>
        </section>
    );
};

export default Why;