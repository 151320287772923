import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import Main from "../parts/Main";
import Layout from "../parts/Layout";
import NotFound from "../templates/NotFound";

const App = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const response = await axios.get(
        "https://new.revined.com/wp-json/wp/v2/pages"
      );
      setPages(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const routes = pages.map((page) => {
    const { id, slug } = page;
    if (slug === "home") {
      return <Route key={id} index element={<Main page={page} />} />;
    } else {
      return (
        <Route key={id} path={`/${slug}`} element={<Main page={page} />} />
      );
    }
  });

  return (
    <>
      <Router>
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-secondary"></div>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Layout />}>
            {routes}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
